<template>
    <section class="work-display work-display--css">
        <div :key="project.id" v-for="project in projects" class="project__container">
            <a :href="project.link" class="link-project" target="_blank">
                <figure class="project-img__container">
                    <img :src="project.img" alt="Miguel Chávez" :title="project.title" :alt="project.title"/>
                </figure>
            </a>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'CSSDrawing',
         data() {
            return {
                projects: []
            }
        },
        methods: {
        },
        created() {
            this.projects = [
            {
                id: 1,
                title: 'Miss Minuto form Loki',
                link: 'https://codepen.io/miguelgchavez/pen/PoKgwvX',
                img: require('../assets/images/projects/miss-minute.png'),
            },
            {
                id: 2,
                title: 'Squid Game from Netflix',
                link: 'https://codepen.io/miguelgchavez/pen/xxLVxZq',
                img: require('../assets/images/projects/squid-game.png')
            },
            {
                id: 3,
                title: 'Spinel from Steven Universe',
                link: 'https://codepen.io/miguelgchavez/pen/MWEeJVO',
                img: require('../assets/images/projects/spinel.png')
            },
            {
                id: 4,
                title: 'Sia',
                link: 'https://codepen.io/miguelgchavez/pen/YzrWNYb',
                img: require('../assets/images/projects/sia.png')
            }
            ]
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .work-display--css{
        display                 : grid;
        grid-template-columns   : 1fr 1fr;
        position                : relative;
        z-index                 : 1;

        .project__container .link-project,
        .project-img__container{
            display         : flex;
            justify-content : center;
            align-items     : center;

            img{
                max-width   : 80%;
                width       : 150px;

                @media(min-width: 768px){
                    max-width   : 100%;
                    width       : 300px;
                }  
                @media(min-width:1200px){ width : 400px; }
                @media(min-width:1500px){ width : 450px; }
            }
        }

        .project__container{
            height          : 160px;

            @media(min-width: 768px){ height: 400px; }
            @media(min-width:1200px){ height: 600px; }

            &:nth-child(1){ background-color: #008081; }
            &:nth-child(2){ background-color: #FF565D; }
            &:nth-child(3){ 
                background-color: #F7E6EE; 

                img{
                    @media(min-width: 1200px){
                        width : 450px; 
                    }
                } 
            }
            &:nth-child(4){ background-color: #C4C5C4; } 

            .link-project{
                width   : 100%;
                height  : 100%;
                cursor  : pointer;
            }
        }
    }
</style>
