<template>

    <header class="header">
        <router-link to="/">
            <picture class="logo-container">
                <source media="(min-width:768px)" srcset="../assets/images/logo/logo-miguel-chavez-desk.png"/>
                <img src="../assets/images/logo/logo-miguel-chavez-mobile.png" alt="Miguel Chávez" class="logo"/>
            </picture>
        </router-link>

        <!--<div class="menu">
            <div></div>
            <div></div>
            <div></div>
        </div>-->
    </header>

     <h1>
      Miguel Chávez, is a <strong>Front Developer and Designer,</strong> with an hobby in <strong>illustration</strong> of abstract shapes and <strong>CSS drawings</strong> who lives and works in Portugal.
    </h1>

</template>

<script>
    export default {
        name: 'Header',
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .header{
        justify-content : space-between;
        padding         : 50px 30px;

        @media(min-width: 1200px){ padding: 90px 200px; }

        .menu{
            justify-content : center;

            div{
                width            : 6px;
                height           : 6px;
                background-color : #111111;
                border-radius    : 50%;
                margin-right     : 5px;

                &:last-child{ margin-right: 0; }

                @media(min-width: 1200px){
                    width       : 12px;
                    height      : 12px;
                    margin-right: 10px;
                }
            }
        }
    }

    .header,
    .menu{
        display         : flex;
        align-items     : center;
    }

    .logo,
    .menu{
        cursor : pointer;
    }

    h1{
      padding: 0 30px 50px;

      @media(min-width: 768px){
          text-align  : center;
          max-width   : 600px;
          margin      : 0 auto;
      }

      @media(min-width: 900px){
          max-width       : 890px;
          padding-bottom  : 90px;
      }
  }
</style>
