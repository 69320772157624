<template>
    <section class="work-display work-display--horzion">
        <div class="project__container">
            <a href="https://www.behance.net/gallery/116709893/Horizon-Forbidden-West-PS5PS4" class="link-project" target="_blank">
                <figure class="project-img__container">
                    <img src="../assets/images/projects/horizon.png" alt="Miguel Chávez - Horizon Forbbiden West" title="Miguel Chávez - Horizon Forbbiden West"/>
                </figure>
            </a>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Horizon',
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .work-display--horzion{
        .project__container{
            background-color: #f1f1f1;

            .project-img__container{
                display         : flex;
                justify-content : center;
                align-items     : center;

                img{
                    max-width: 90%;
                }
            }
        }
    }
</style>
