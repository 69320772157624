<template>
  <div class="home">
    <Header />
   
    <Auchan />
    <CSSDrawing />
    <Horizon />
    <Shapes />

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Shapes from '@/components/Shapes.vue'
import Horizon from '@/components/Horizon.vue'
import CSSDrawing from '@/components/CSSDrawing.vue'
import Auchan from '@/components/Auchan.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Shapes,
    Horizon,
    CSSDrawing,
    Auchan,
    Footer
  }
}
</script>