<template>
    <section class="work-display work-display--auchan">
        <div :key="project.id" v-for="project in projects" class="project__container">
            <a :href="project.link" class="link-project" target="_blank">
                <figure class="project-img__container">
                    <img :src="project.img" alt="Miguel Chávez" :title="project.title" :alt="project.title"/>
                </figure>
            </a>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Auchan',
         data() {
            return {
                projects: []
            }
        },
        methods: {
        },
        created() {
            this.projects = [
            {
                id: 1,
                title: 'Auchan Tech',
                link: 'https://www.auchan.pt/pt/tecnologia-e-eletrodomesticos/',
                img: require('../assets/images/projects/auchan-tech.png'),
            },
            {
                id: 2,
                title: 'Auchan New Website',
                link: 'https://www.auchan.pt/pt/lancamento-novo-site.html',
                img: require('../assets/images/projects/auchan-novo-site.png')
            },
            {
                id: 3,
                title: 'Auchan Christmas Decoration',
                link: 'https://www.auchan.pt/pt/casa-e-jardim/casa-natal/',
                img: require('../assets/images/projects/auchan-novo-ciclo.png')
            },
            {
                id: 4,
                title: 'Auchan Wine',
                link: 'https://www.auchan.pt/pt/bebidas-e-garrafeira/garrafeira/',
                img: require('../assets/images/projects/auchan-garrafeira.png')
            }
            ]
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .work-display--auchan{
        display                 : grid;
        grid-template-columns   : 1fr 1fr;
        gap                     : 30px;
        background-image        : linear-gradient(45deg, #FF3D00, #B90005);
        padding                 : 30px;
        overflow                : hidden;

        @media(min-width: 768px){
            padding: 50px;
            gap    : 50px;
        }

        @media(min-width:1200px){
            grid-template-columns : repeat(4, 1fr);
            padding               : 90px;
        }

        .project__container{
            @media(min-width: 1400px){
                display         : flex;
                justify-content : center;
                align-items     : center;
            }

            &:nth-child(1),
            &:nth-child(3){
                position    : relative;
                margin-top  : -40px;

                @media(min-width: 768px){ margin-top  : -120px; }
                @media(min-width:1200px){ margin-top  : 0; }
            }
        }
    }
</style>
