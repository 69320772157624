<template>
  <router-view/>
</template>

<style lang="scss">
/*****Font*****/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

/*****Reset*****/
*{ box-sizing: border-box; }

html{
  font-size: 10px;
}

html, body,
h1, h2, h3, h4, h5, h6, p, a,
section, article, figure,
blockquote, cite, video,
form, input, textarea, button,
header, nav, main, footer,
ul, ol {
  margin                        : 0;
  padding                       : 0;
  outline                       : 0;
  -webkit-tap-highlight-color   : rgba(0, 0, 0, 0);
  display                       : block;
}

strong{
  font-weight: 700;
}

img, video {
  max-width : 100%;
}

a {
  text-decoration   : none;
  text-transform    : none;

  &:active,
  &:focus{
    color           : inherit;
    background-color: inherit;
  }
}

button {
  background-color  : transparent;
  border            : 0;
}

@mixin flex-center {
  display         : flex;
  justify-content : center;
  align-items     : center;
}

/*****BODY*****/
body{
  max-width   : 1920px;
  margin      : 0 auto;
  font-family : 'Montserrat', sans-serif;
  font-weight : 400;
  min-height  : 100vh;
}

h1,
h2{
  font-weight     : 400;
  font-size       : 1.8rem;
  line-height     : 25px;

  @media(min-width: 768px){ 
    font-size     : 2.2rem;
    line-height   : 32px;
  }

  @media(min-width:1200px){ 
    font-size     : 3rem; 
    line-height   : 42px;
  }
}

h2{
  font-weight     : 700;
}

</style>
